import React from "react";

interface CityInputProps {
  label: string;
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onSearchClick: () => void;
}

export const CityInput = ({
  label,
  value,
  onChange,
  onSearchClick,
}: CityInputProps) => {
  return (
    <form onSubmit={event => event.preventDefault()}>
      <label>
        <div>{label}</div>
        <input type="text" value={value} onChange={onChange} />
      </label>{" "}
      <button onClick={onSearchClick}>search</button>
    </form>
  );
};
