import React from "react";
import styled from "styled-components";

interface ForecastDatum {
  dt: number;
  main: {
    temp: number;
  };
}

interface WeatherDisplayProps {
  forecastData: {
    list: ForecastDatum[];
    message: string;
  };
}

function getDayOfWeek(date: Date) {
  const day = date.getDay();
  if (day == 0) {
    return "Sun";
  } else if (day == 1) {
    return "Mon";
  } else if (day == 2) {
    return "Tue";
  } else if (day == 3) {
    return "Wed";
  } else if (day == 4) {
    return "Thu";
  } else if (day == 5) {
    return "Fri";
  }
  return "Sat";
}

function getTime(date: Date) {
  let hour = date.getHours();
  let suffix = "AM";
  if (hour >= 12) {
    suffix = "PM";
  }
  if (hour > 12) {
    hour -= 12;
  }
  return `${hour} ${suffix}`;
}

function groupByDay(forecastData: ForecastDatum[]) {
  const groupedData = [];

  let previousDay = "";
  for (const forecastDatum of forecastData) {
    const date = new Date(forecastDatum.dt * 1000);
    const day = getDayOfWeek(date);
    if (day == previousDay) {
      groupedData[groupedData.length - 1].push(forecastDatum);
    } else {
      groupedData.push([forecastDatum]);
    }
    previousDay = day;
  }
  return groupedData;
}

export const ForecastWeatherDisplay = ({
  forecastData,
}: WeatherDisplayProps) => {
  if (forecastData == null) {
    return null;
  } else if (forecastData.message) {
    return <p>{forecastData.message}</p>;
  } else {
    let previousDay = "";
    const groupedForecastData = groupByDay(forecastData.list);
    return (
      <>
        {groupedForecastData.map(dayForecastData => {
          return (
            <Row>
              {dayForecastData.map(datum => {
                const date = new Date(datum.dt * 1000);
                let day = getDayOfWeek(date);
                console.log("previousDay", previousDay);
                if (day == previousDay) {
                  day = "-";
                } else {
                  previousDay = day;
                }
                return (
                  <ForecastItem>
                    <div>{day}</div>
                    <div>{getTime(date)}</div>
                    <div>{datum.main.temp}&deg; F</div>
                  </ForecastItem>
                );
              })}
            </Row>
          );
        })}
      </>
    );
  }
};

const ForecastItem = styled.div`
  flex-shrink: 0;
  text-align: center;
  margin-right: 16px;
  flex-basis: 90px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;
