import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import { Weather } from "../pages-components/weather/Weather"

const Page = () => {
  return (
    <Layout>
      <SEO title="Weather Application" />
      <h1>What's the weather today?</h1>
      <Weather />
    </Layout>
  );
};

export default Page;
