import React, { useState, useEffect } from "react";
import { CityInput } from "./Weather/CityInput";
import { CurrentWeatherDisplay } from "./Weather/CurrentWeatherDisplay";
import { ForecastWeatherDisplay } from "./Weather/ForecastWeatherDisplay";

export const Weather = () => {
  const [currentWeatherData, setCurrentWeatherData] = useState(null);
  const [forecastData, setForecastData] = useState(null);
  const [requestedCity, setRequestedCity] = useState("");
  const [performSearch, setPerformSearch] = useState(false);

  const openWeatherMapApiKey = "7262ef28e81e386bbb54779cfea46aff";

  const currentWeatherUrl = `https://api.openweathermap.org/data/2.5/weather?q=${requestedCity}&appid=${openWeatherMapApiKey}`;

  const forecastUrl = `https://api.openweathermap.org/data/2.5/forecast?q=${requestedCity}&appid=${openWeatherMapApiKey}&units=imperial`;

  useEffect(() => {
    if (performSearch) {
      fetch(currentWeatherUrl)
        .then(response => response.json())
        .then(fetchData => {
          console.log("fetchData:", fetchData);
          setCurrentWeatherData(fetchData);
        });
      setPerformSearch(false);
    }
  }, [performSearch, currentWeatherUrl]); // dependency array

  useEffect(() => {
    if (performSearch) {
      fetch(forecastUrl)
        .then(response => response.json())
        .then(fetchData => {
          console.log("fetchData:", fetchData);
          setForecastData(fetchData);
        });
      setPerformSearch(false);
    }
  }, [performSearch, forecastUrl]); // dependency array

  const onSearchClick = () => {
    setPerformSearch(true);
  };

  return (
    <React.Fragment>
      <CityInput
        label="City:"
        value={requestedCity}
        onChange={event => {
          setRequestedCity(event.target.value);
        }}
        onSearchClick={onSearchClick}
      />
      <CurrentWeatherDisplay currentWeatherData={currentWeatherData} />
      <ForecastWeatherDisplay forecastData={forecastData} />
      {/* <pre>{JSON.stringify(forecastData, null, 2)}</pre> */}
    </React.Fragment>
  );
};
