import React from "react";

interface WeatherDisplayProps {
  currentWeatherData: {
    name: string;
    main: {
      temp: number;
    };
    message: string;
  };
}

export const CurrentWeatherDisplay = ({
  currentWeatherData,
}: WeatherDisplayProps) => {
  if (currentWeatherData == null) {
    return null;
  } else if (currentWeatherData.message) {
    return <p>{currentWeatherData.message}</p>;
  } else {
    const cityName = currentWeatherData.name;
    const temperatureF = (
      ((currentWeatherData.main.temp - 273.15) * 9) / 5 +
      32
    ).toFixed(2);
    const temperatureC = (currentWeatherData.main.temp - 273.15).toFixed(2);
    return (
      <React.Fragment>
        <p>The temperature is {temperatureF}&deg;F.</p>
        <p>The temperature is {temperatureC}&deg;C.</p>
        <p>The city is {cityName}.</p>
      </React.Fragment>
    );
  }
};
